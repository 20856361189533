@tailwind base;
@tailwind components;
@tailwind utilities;

.cardItem {
  transition: 0.3s;
}
.cardContainer:hover > :not(:hover) {
  opacity: 0.4;
}

/* .swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
} */
